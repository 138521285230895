
import React, { useState, useEffect } from 'react'
import escapeHtml from 'escape-html'
import RichText from "./components/RichText";
import { Text } from 'slate'
import styled from "styled-components";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationManager} from 'react-notifications';




const CheckBoxWrapper = styled.div`
position: relative;
`;
const CheckBoxLabel = styled.label`
position: absolute;
top: 0;
left: 0;
width: 42px;
height: 26px;
border-radius: 15px;
background: #bebebe;
cursor: pointer;
&::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 4px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}
`;
const CheckBox = styled.input`
opacity: 0;
z-index: 1;
border-radius: 15px;
width: 42px;
height: 20px;
&:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-left: 21px;
    transition: 0.2s;
    }
}
`;



function CreateCampaign() {

    let navigate = useNavigate();






    const serialize = node => {

        console.log('node', node);

        if( Array.isArray(node) ){

            return node.map( n => serialize(n)).join('');

        }


        if (Text.isText(node)) {
          let string = escapeHtml(node.text);
          let output = '';

          let tags = [];

          if (node.bold) {
            tags.push('strong');
          }

          if (node.italic) {
            tags.push('em');
          }
          if (node.underline) {
            tags.push('u');
          }

          tags.forEach(tag => output += `<${tag}>`);

          output += string; 

          tags.reverse().forEach(tag => output += `</${tag}>`);



        //   if (node.italic) {
        //     string = `<em>${string}</em>`
        //   }
        //   if (node.underline) {
        //     string = `<u>${string}</u>`
        //   }
        //   if (node.bold) {
        //     string = `<strong>${string}</strong>`
        //   }
          return output
        }
      
        const children = node.children.map(n => serialize(n)).join('')
      
        switch (node.type) {
          case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`
          case 'paragraph':
            return `<p>${children}</p>`
          case 'aligncenter':
            return `<p style="text-align:center;">${children}</p>`
          case 'alignleft':
            return `<p style="text-align:left;">${children}</p>`
          case 'alignright':
            return `<p style="text-align:right;">${children}</p>`
          case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`
          default:
            return children
        }
    }

    const initialValue = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
    ];


    const [value, setValue] = useState(initialValue);

    const [campaignName, setCampaignName] = useState();
    const [campaignTitle, setCampaignTitle] = useState();
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState(null);

    const [cover, setCover] = useState(null);
    const [coverName, setCoverName] = useState("");
    const [logoName, setlogoName] = useState("");
    const [preview, setPreview] = useState(false);
    const [id, setId] = useState(null);
    const [active, setActive] = useState(null);




    const [options, setOptions] = useState([
        {
            
                id: 'firstname',
                type: 'text',
                rquired: false,
                selected: false,
                placeholder: 'First name',
                options: []
            
        },
        {
            
                id: 'lastname',
                type: 'text',
                rquired: false,
                selected: false,
                placeholder: 'Last name',
                options: []
            
        },
        {
            
                id: 'email',
                type: 'email',
                rquired: true,
                selected: true,
                placeholder: 'Email',
                options: []
            
        },
        {
            
                id: 'phone',
                type: 'text',
                rquired: false,
                selected: false,
                placeholder: 'Phone number',
                options: []
            
        },
    ]);

    useEffect( () => {

        const input = document.querySelector('.active input');
        if( input ){
            input.focus()
        }


    } , [options, active])



    let formErrors = [];


    const validateForm = () => {
        let has_error = false;

        formErrors = [];



        if( !campaignName || campaignName == undefined || campaignName == '' ){
            formErrors.push({
                message : 'Campaign name field required.'
            })

            has_error = true;
        }
        if( !campaignTitle || campaignTitle == undefined || campaignTitle == ''  ){
            formErrors.push({
                message : 'Campaign title field required.'
            })
            has_error = true;
        }
        if( !logo  ){
            formErrors.push({
                message : 'Campaign logo required.'
            })
            has_error = true;
        }
        if( !cover  ){
            formErrors.push({
                message : 'Campaign cover required.'
            })
            has_error = true;
        }


        const unvalidFields = options.filter( option => {
            return option.id == '' || option.id == "untitled"
        })

        if( unvalidFields.length > 0 ){
            formErrors.push({
                message : 'Campaign fields are not valide.'
            })
            has_error = true;
        }


        

        console.log( '--- form validation --- ')
        console.log( 'formErrors' , formErrors);
        console.log( 'has_error' , has_error);
        console.log( 'value' , value);
        console.log( 'logo' , logo);
        console.log( 'options' , options);
        console.log( 'unvalidFields' , unvalidFields);


        return has_error;
    }

    const handlePreview = (e) => {
        e.preventDefault();

        if( validateForm() ){

            formErrors.forEach( error => {
                NotificationManager.error( error.message );
            })

            return;
        }

        var data = new FormData();

        data.append('update', false);

        data.append('name', campaignName);
        data.append('title', campaignTitle);
        data.append('logo', logo);
        data.append('cover', cover);
        data.append('fields', JSON.stringify(options));
        data.append('content', value);
        data.append('status', 'preview');

        setLoading(true);

        axios.post(`/api/campaigns`, data)
        .then(res => {

            setLoading(false);

            setPreview(true);

            setId(res.data.campaign.id)
            
            window.open(`/${res.data.campaign.slug}`, '_blank');

        });
    }


    const handleFormSubmit = (e) => {
        e.preventDefault();

        if( validateForm() ){

            formErrors.forEach( error => {
                NotificationManager.error( error.message );
            })

            return;
        }


        var data = new FormData();

        if( preview ){
            data.append('update', true);
        }
        data.append('id', id);
        data.append('name', campaignName);
        data.append('title', campaignTitle);
        data.append('logo', logo);
        data.append('cover', cover);
        data.append('fields', JSON.stringify(options));
        data.append('content', value);
        data.append('status', 'published');

        setLoading(true);

        let url = '';

        if( id ){
            url = `/api/campaigns/${id}`;
        }else{
            url = `/api/campaigns/`;
        }

        


        axios.post(url, data)
        .then(res => {

            setLoading(false);

            

            navigate('/campaigns');

            NotificationManager.success(`Campaign "${campaignName}" created successfully.`);
            

        });

    }


    const handleRquiredChange = (id) => {


        let mapped = options.map(option => {
            return option.id === id ? { ...option, rquired: !option.rquired } : { ...option};
        });
    
        setOptions(mapped);
    }

    function string_to_slug (str) {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();
      
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaeeeeiiiioooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
    
        str = str.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    
        return str;
    }

    const handleActivePanel = id => setActive(id);

    const handleNameField = (id, text) => {

        let slug = text;

        if( slug === '' ){
            slug = id;
        }


        let mapped = options.map(option => {
            return option.id === id ? { ...option, id: string_to_slug(slug), placeholder: text} : { ...option};
        });
    
        setOptions(mapped);
    }
    const handleNameSelectField = (id, text) => {

        let slug = text;

        if( slug === '' ){
            slug = id;
        }


        let mapped = options.map(option => {
            return option.id === id ? { ...option, id: string_to_slug(slug), placeholder: text} : { ...option};
        });
    
        setOptions(mapped);
    }

    const handleNameSelectOptionField = (id, value) => {
        let mapped = options.map(option => {



            let old_options = option.options.filter( item => item != 'untitled')

            old_options = old_options ? [...old_options, value] : []
            return option.id === id ? { ...option, options: old_options} : { ...option};
        });
    
        setOptions(mapped);
    }

    const handleAddSelectOption = ( id,e ) => {
        e.preventDefault();

        let mapped = options.map(option => {
            return option.id === id ? { ...option, options: [...option.options,"untitled"]} : { ...option};
        });
    
        setOptions(mapped);
    }

    const handleSelected = (id) => {
        let mapped = options.map(option => {
            return option.id === id ? { ...option, selected: !option.selected } : { ...option};
        });
    
        setOptions(mapped);
    }

    const handleDeleteOption = (id) => {


        let mapped = options.filter(option => {
            return option.id !== id;
        });
    
        setOptions(mapped);
    }

    const handleAddOption = (e) => {
        e.preventDefault();


        const search = options.find( option => option.id == 'untitled')

        if( search ){
            setActive('untitled');
            NotificationManager.error(`You have already inserted an unamed field.`);
            return;
        } 

        // let copy = [...options];
        let copy = [...options, { 
            id: 'untitled',
            type: 'text',
            rquired: false,
            placeholder: '',
            options: []
            
         }];   
         setOptions(copy);

         setActive('untitled');


    }


    const handleAddMultipleOption = (e) => {
        e.preventDefault();


        const search = options.find( option => option.id == 'untitled')

        if( search ){
            setActive('untitled');
            NotificationManager.error(`You have already inserted an unamed field.`);
            return;
        } 

       // let copy = [...options];
        let copy = [...options, { 
            id: 'untitled',
            type: 'dropdown',
            rquired: false,
            placeholder: '',
            options:[]
         }];   

         console.log('copy', copy)

         setOptions(copy);

         setActive('untitled');

    }

    const handleCover = (e) =>{
        console.log(e.target.files[0]);

        setCover(e.target.files[0])
        setCoverName(e.target.files[0].name)

    }
    const handleLogo = (e) =>{
        console.log(e.target.files[0]);

        setLogo(e.target.files[0])
        setlogoName(e.target.files[0].name)

    }


    const listitems = options.map( option => (

        <li key={option.id} className={`field-${option.type} ${active == option.id ? 'active' : ''}`}>
            <div className='multi-select-item'>
                

                {
                    option.id == 'untitled' && option.type == 'text' ?

                    <input type="text" placeholder='Name your field' onClick={e => handleActivePanel(option.id)} onBlur={e => handleNameField(option.id, e.target.value)}/>

                    : ""
                }
                {
                    option.id == 'untitled' && option.type == 'email' ?

                    <input type="text" placeholder='Name your field' onClick={e => handleActivePanel(option.id)}  onBlur={e => handleNameField(option.id, e.target.value)}/>

                    : ""
                }
                {
                    option.type == 'dropdown' ?

                    <div className='select'>
                        <div className='placeholder'><input type="text" placeholder={option.id == 'untitled'? 'Name your field': option.placeholder} onClick={e => handleActivePanel(option.id)}  onBlur={e => handleNameSelectField(option.id, e.target.value)}/></div>
                            <div className='options'>
                            { 
                                option.options.map( item => (
                                    <div className='placeholder'><input type="text" placeholder={item == 'untitled' ? 'Name your option': option}   onBlur={e => handleNameSelectOptionField(option.id, e.target.value)}/></div>
                                )
                            )
                            }
                            <button onClick={(e)=> handleAddSelectOption(option.id,e)}>Add option</button>
                        </div>
                    </div>

                    : ""
                }
                {
                    option.id != 'untitled' && ( option.type == 'text' || option.type == 'email' ) ?

                    <input type="text" placeholder={option.placeholder} onClick={e => handleActivePanel(option.id)}  onBlur={e => handleNameField(option.id, e.target.value)}/>

                    : ''
                }
                
                {

                    <div className='close'>
                        <button onClick={()=> handleDeleteOption(option.id)}>
                            x
                        </button>
                    </div>

                }
            </div>
            {

                <div className='required'>
                    
                    <span>Required</span>

                    <CheckBoxWrapper>
                        
                        <CheckBox id={`required-${option.id}`} type="checkbox" checked={option.rquired} onChange={()=> handleRquiredChange(option.id)}/>
                        <CheckBoxLabel htmlFor={`required-${option.id}`} />
                    </CheckBoxWrapper>
                </div>
            }

        </li>
    ))





    return (
        <div className="container-large">
            <div className="content-with-sidebar">
                <div className="sidebar">
                    <h3>Inventory</h3>
                    <div className="buttons">
                        <button>All smart contracts</button>
                        <button>Draft contracts <span>0</span></button>
                        <button>Contracts ranking</button>
                    </div>
                </div>
                <div className="content">
                    <div className="header">
                        <ul className="breadcrumbs">
                            <li><a href="#">
                                <img src="app/build/images/home.png" />
                                <span>Dashboard</span>
                                
                            </a></li>
                            <li><a href="#">NFT infos</a></li>
                            <li><a href="#">Create a campaign</a></li>
                        </ul>
                        <div className="steps">
                            <button className="active">
                                <div className="step">1</div>
                                <div className="text">NFT infos</div>
                            </button>
                            <button>
                                <div className="step">2</div>
                                <div className="text">Create <br />campaign</div>
                            </button>
                            <button>
                                <div className="step">3</div>
                                <div className="text">Publish</div>
                            </button>
                        </div>
                    </div>
                    <div className="header">
                        <h3 className="heading">Create a new campaign</h3>

                    </div>

                    <form action="#" onSubmit={handleFormSubmit}>

                        <div className="form-line two-inputs">
                            <div className="input input-text">
                                <input type="text" value={campaignName} onChange={e => setCampaignName(e.target.value)}  placeholder="Campaign name" />
                            </div>
                            <div className="input input-text">
                                <input type="text" value={campaignTitle} onChange={e => setCampaignTitle(e.target.value)}  placeholder="Title" />
                            </div>
                        </div>
                        <div className="form-line form-line-uploads">
                            <div className="input input-upload input-upload-primary">
                                <input type="file" name="file" id="banner" className="inputfile"  onChange={handleCover} accept="image/png, image/gif, image/jpeg"/>
                                <label htmlFor="banner">
                                    <img src="app/build/images/plus-rounded.png" alt="" />
                                    {
                                        cover 
                                        ? <div className="text">{coverName}</div>
                                        : <div className="text">Add banner <span>( Jpeg or png )</span></div>
                                    }
                                </label>
                            </div>
                            <div className="input input-upload input-upload-secondary">
                                <input type="file" name="file" id="logo" className="inputfile"  onChange={handleLogo} accept="image/png, image/gif, image/jpeg"/>
                                <label htmlFor="logo">
                                    <img src="app/build/images/plus-rounded.png" alt="" />
                                    {
                                        logo 
                                        ? <div className="text">{logoName}</div>
                                        : <div className="text">Add Logo <span>( Jpeg or png )</span></div>
                                    }
                                </label>
                            </div>


       
                        </div>

                        <div className='form-line-editor'>
                            <RichText handleChange={value => {


                                const content = serialize(value);
                                //localStorage.setItem('content', content);
                                console.log('content',content);
                                setValue(content);
  
                            }} />
                        </div>

                        <div className='form-line form-line-row'>
                            <h4>Select datas you want to collect</h4>

                            <div className='multi-select'>
                                <ul>
                                    {listitems}
                                </ul>
                            </div>

                            <div className='multi-select-actions'>
                                <button onClick={handleAddOption}>
                                    <span className='icon'>+</span>
                                    <span className='text'>Add single choice</span>
                                </button>
                                <button onClick={handleAddMultipleOption}>
                                    <span className='icon'>+</span>
                                    <span className='text'>Add multiple choices</span>
                                </button>
                            </div>
                        </div>

                        <div className='preview'>
                            <button onClick={handlePreview}>
                                <span className='icon'><img src="app/build/images/icon-preview.png" /></span>
                                <span className='text'>Click here for a preview of your campaign</span>
                            </button>
                        </div>

                        <div className="form-line form-line-actions">
                            <button className="btn btn-draft" type='submit'>Save draft</button>
                            <button className="btn btn-primary" type='submit'>Validate</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
  }
  
  export default CreateCampaign;
  