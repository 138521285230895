import { Link } from "react-router-dom";

import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";

function AppHeader() {
    return (
        <header>
            <div className="container-large">
                <div className="header-wrapper">
                    <div className="logo">
                        <a href="#">
                            <img src="/images/logo.png" />
                        </a>
                    </div>
                    <div className="header-content">
                        <div className="header-content-top">
                            <div className="account">
                                <button>

                                    <span>Lizandro Cruz</span>
                                    <img src="/images/account.png" />
                                    
                                </button>
                            </div>
                            <div className="notifications">
                                <button>
                                    <img src="/images/notification.png" />
                                    <span className="badge badge-danger">
                                        2
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="header-content-bottom">

                            <nav>
                                <ul>
                                    <li>
                                        <Link to="campaigns">
                                            <span>Campaigns</span>
                                            <span className="badge">3</span>
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <a href="#">NFT Smart Contract</a>
                                    </li>
                                    <li>
                                        <a href="#">Analytics</a>
                                    </li>
                                    <li>
                                        <a href="#">Wallet</a>
                                    </li>
                                </ul>
                            </nav>

                            <div className="cta">
                                <p>Need some help ? <strong><a href="#">Click here</a></strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </header>
    );
  }
  
  export default AppHeader;
  