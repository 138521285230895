import { Link } from "react-router-dom";
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {NotificationContainer, NotificationManager} from 'react-notifications';

function ShowCampaigns() {

    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        axios.get(`/api/campaigns`)
        .then(res => {

            setCampaigns(res.data);
            

        });
    },[])

    const handleDelete = (campaign) => {
        axios.delete(`/api/campaigns/${campaign.id}`)
        .then(res => {

            if( !res.error ){
                const mapped = campaigns.filter( item => {
                    return campaign.id !== item.id
                })

                setCampaigns(mapped);

                NotificationManager.success(`Campaign "${campaign.name}" deleted successfully.`);
            }

            

        });
    }

    const handlePreview = (campaign) => window.open(`/${campaign.slug}`, '_blank');


    return (
        <div className="container-large">
            <div className="content-with-sidebar listing-page">
                <div className="sidebar">
                    <h3>Inventory</h3>
                    <div className="buttons">
                        <button>All smart contracts</button>
                        <button>Draft contracts <span>0</span></button>
                        <button>Contracts ranking</button>
                    </div>
                </div>
                <div className="content">
                    <div className="header">
                        <ul className="breadcrumbs">
                            <li><a href="#">
                                <img src="app/build/images/home.png" />
                                <span>Dashboard</span>
                                
                            </a></li>
                            <li><a href="#">NFT infos</a></li>
                            <li><a href="#">Create a campaign</a></li>
                        </ul>
                    </div>
                    <div className="header">
                        <h3 className="heading">Campaigns</h3>
                        <div className="actions">
                            <Link to="/campaigns/create" className="btn btn-primary">
                                Create new campaign
                            </Link>
                        </div>
                    </div>
                    <div className="listing">
                        { campaigns.length ?
                        <table>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Leads</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                                {
                                    campaigns.map( campaign => {
                                        return(
                                            <tr key={campaign.id}>
                                                <td>{campaign.name}</td>
                                                <td>{moment(campaign.created_at).format("DD/MM/YYYY")}</td>
                                                <td>{campaign.leads_count}</td>
                                                <td>
                                                    <button onClick={() => handlePreview( campaign )}>
                                                        <img src="app/build/images/eye.png" />
                                                    </button>
                                                </td>
                                                <td>
                                                    <button onClick={() => handleDelete( campaign )}>
                                                        <img src="app/build/images/delete.png" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    
                                }
                            </tbody>

                        </table>
                        :

                        <p>No campaigns found in your account <Link to="/campaigns/create">Create a new one!</Link></p>
                        
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShowCampaigns;