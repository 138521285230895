
import React from 'react'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import AppHeader from './AppHeader.js';
import CreateCampaign from './CreateCampaign.js';
import HomePage from './HomePage.js';
import ShowCampaigns from './ShowCampaigns.js';

import 'react-notifications/lib/notifications.css';

import {NotificationContainer, NotificationManager} from 'react-notifications';




function App() {
  return (
    <React.Fragment>
      
      <BrowserRouter>
        <AppHeader/>
        <Routes>

          <Route path="/" element={<HomePage />} />
          <Route path="campaigns" element={<ShowCampaigns />}/>
          <Route path="campaigns/create" element={<CreateCampaign />} />

          

    
        </Routes>
      </BrowserRouter>

      <NotificationContainer/>
        
    </React.Fragment>

  );
}

export default App;
